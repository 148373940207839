import React from "react";
import "./Main.css";
import Header from './Header';
import Content from './Content';
import bgimf from './background-image.jpg'
import PrivacyPolicyFooter from "./PrivacyPolicyFooter";

// const stylebg = {
//     backgroundImage: `url(${bgimf})`,
//     backgroundAttachment: "fixed",
//     backgroundRepeat: "noRepeat",
//     backgroundPosition: "center",
//     backgroundSize: "cover",
//     minHeight: "100vh",
//     display: "block",
//     height: "100%",
//     padding: "0px",
//     margin: "0px"
// }


const stylebg = {
    backgroundImage: `url(${bgimf})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
}

const Main = () => {
    return (
        <div style={stylebg}>
            <Header />
            <Content />
            <div style={{ marginTop: "auto", paddingTop: '5px' }}>
                <PrivacyPolicyFooter />
            </div>
        </div>
    );
}

export default Main;
